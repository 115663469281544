<template>
    <div class="app-container">
        <!-- <dd-card class="box-card" :title="'Filtre'" :collapsed.sync="isCollapsed"
			@toggledCard="isCollapsed = !isCollapsed">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">De la </div>
					<el-date-picker class="fullWidth" v-model="filters.from.value" value-format="yyyy-MM-dd" type="date"
						@change="applyFilters">
					</el-date-picker>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Pana la </div>
					<el-date-picker class="fullWidth" v-model="filters.to.value" value-format="yyyy-MM-dd" type="date"
						@change="applyFilters">
					</el-date-picker>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Comentarii</div>
					<el-select class="fullWidth" v-model="filters.comments.value" placeholder="Toate"
						@change="applyFilters">
						<el-option label="Toate" value=""></el-option>
						<el-option label="Cu comentarii" value="1"></el-option>
						<el-option label="Fara comentarii" value="2"></el-option>
					</el-select>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Tip evaluari</div>
					<el-select class="fullWidth" v-model="filters.evaltype.value" placeholder="Toate"
						@change="applyFilters">
						<el-option label="Toate" value=""></el-option>
						<el-option label="Pozitive" value="3"></el-option>
						<el-option label="Neutre" value="2"></el-option>
						<el-option label="Negative" value="1"></el-option>
					</el-select>
				</el-col>
			</el-row>
		</dd-card> -->
        <!-- <Box :type="'warning'" :active="true">
			<div slot="box-body">
				<ul class="rawUl">
					<li><strong>Diferentele mai mici de 50 lei rezultate in urma compensarii vor fi cumulate in
							urmatoarea
							compensare.</strong></li>
				</ul>
			</div>
		</Box> -->

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Filtre</span>
                <span style="float: right">
                    <el-button @click.native="applyFilters" type="primary" size="mini" icon="el-icon-check" plain>{{
                        $t('payments.compensations.apply')
                    }}</el-button>
                    <el-button @click.native="resetFilters" type="warning" size="mini" icon="el-icon-refresh" plain>{{
                        $t('payments.compensations.reset')
                    }}</el-button>
                </span>
            </div>

            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('payments.compensations.interval') }}</div>
                    <el-select class="interval-select fullWidth" v-model="filters.selectedInterval.value" clearable>
                        <el-option
                            v-for="item in intervalsStrings"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('payments.compensations')["comp-id"] }}</div>
                    <el-input :placeholder="$t('payments.compensations')['comp-id']" v-model="filters.compensationID.value" />
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('payments.compensations.status') }}</div>
                    <el-select
                        v-model="filters.status.value"
                        :placeholder="$t('payments.compensations.placeholder-status')"
                        class="fullWidth"
                    >
                        <el-option
                            v-for="status in statuses"
                            :key="status.value"
                            :label="status.text"
                            :value="status.value"
                        ></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-card>

        <div class="separator"></div>
        <div class="compensations-list">
            <template v-if="compensations.length > 0">
                <dd-card
                    :collapsed="i == 0 ? 1 : 0"
                    class="box-card compensation-card"
                    v-for="(c, i) in compensations"
                    :key="i"
                    :title="``"
                >
                    <div slot="title">
                        <span style="margin-right: 5px;">
                            <el-tag v-if="c.canceled && c.canceled == 1" type="danger" size="mini">Anulata</el-tag>
                            <el-tag v-else-if="c.facturiImportate == 1" type="success" size="mini">Definitivata</el-tag>
                            <el-tag v-else-if="c.status >= 0" type="warning" size="mini">Activa</el-tag>
                        </span>
                        Compensare interval <span class="color-cel">{{ formatInterval(c.interval) }}</span>
                    </div>
                    <el-row :gutter="10">
                        <div class="comp-message" v-html="compMessage(c)"></div>
                        <el-col class="details-container" :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
                            <div class="list-header">
                                <div class="item">
                                    <div class="left-values">
                                        <el-button
                                            icon="el-icon-download"
                                            style="width:100%"
                                            type="primary"
                                            @click="downloadCompensation(c.id)"
                                            >Descarca documentul</el-button
                                        >
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="left-title">ID Compensare</div>
                                    <div class="left-values">{{ c.id }}</div>
                                </div>
                                <div class="item">
                                    <div class="left-title">Ultima actualizare</div>
                                    <div class="left-values">{{ c.lastUpdate }}</div>
                                </div>
                                <div class="item">
                                    <div class="left-title">Luna</div>
                                    <div class="left-values">{{ c.luna }}</div>
                                </div>
                                <div class="item">
                                    <div class="left-title">Interval</div>
                                    <div class="left-values">{{ `${c.interval.start} - ${c.interval.end}` }}</div>
                                </div>
                                <div class="item">
                                    <div class="left-title">Compensat</div>
                                    <div class="left-values">
                                        <span class="color-cel">{{ parseFloat(c.sumaCompensata).toFixed(2) }}</span> lei
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="left-title">Ramas de plata</div>
                                    <div class="left-values">
                                        <span class="color-cel">{{ parseFloat(c.ramasDeIncasat).toFixed(2) }}</span> lei
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="left-title">Ramas de incasat</div>
                                    <div class="left-values">
                                        <span class="color-cel">{{ parseFloat(c.ramasDePlatit).toFixed(2) }}</span> lei
                                    </div>
                                </div>
                            </div>
                            <div class="remaining-stuff">
                                <div class="">
                                    <div class="left-title">Comenzi care nu au intrat in compensare</div>
                                    <div class="mini-separator"></div>
                                    <el-table
                                        border
                                        show-summary
                                        :summary-method="getSummariesNotIncluded"
                                        :data="c.comenziRamase"
                                        style="width: 100%"
                                    >
                                        <el-table-column prop="oid" label="ID Comanda"> </el-table-column>
                                        <el-table-column sortable prop="ramas" label="Suma">
                                            <template slot-scope="scope">
                                                {{ parseFloat(scope.row.ramas).toFixed(2) }}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="separator"></div>
                                <div class="">
                                    <div class="left-title">Facturi care nu au intrat in compensare</div>
                                    <div class="mini-separator"></div>
                                    <el-table
                                        border
                                        show-summary
                                        :summary-method="getSummariesNotIncluded"
                                        :data="c.facturiRamase"
                                        style="width: 100%"
                                    >
                                        <el-table-column prop="nr_factura" label="Nr Factura"> </el-table-column>
                                        <el-table-column sortable prop="ramas" label="Suma">
                                            <template slot-scope="scope">
                                                {{ parseFloat(scope.row.ramas).toFixed(2) }}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-col>
                        <el-divider direction="vertical"></el-divider>
                        <el-col class="tables-container" :xs="24" :sm="24" :md="14" :lg="16" :xl="18">
                            <div class="orders">
                                <div class="title">
                                    <span class="color-cel">{{ compensationCompany(c) }}</span> datoreaza
                                    <span class="color-cel">{{ userName }}</span> suma de
                                    <span class="color-cel">{{ parseFloat(c.sumaCompensata).toFixed(2) }}</span> lei
                                    conform detaliilor de mai jos:
                                </div>
                                <div v-if="typeof c.restanteCel !== 'undefined' && c.restanteCel.length > 0">
                                    <div class="sub-title">
                                        Plati restante <span class="color-cel">{{ compensationCompany(c) }}</span>
                                    </div>
                                    <el-table comp-table border :data="c.restanteCel" style="width: 100%">
                                        <el-table-column prop="id" label="ID"> </el-table-column>
                                        <el-table-column prop="data" label="Data"> </el-table-column>
                                        <el-table-column prop="detalii" label="Observatii"> </el-table-column>
                                        <el-table-column prop="valoare" label="Valoare"> </el-table-column>
                                        <el-table-column prop="ramas" label="Suma compensata">
                                            <template slot-scope="scope">
                                                {{
                                                    parseFloat(
                                                        parseFloat(scope.row.valoare) - parseFloat(scope.row.ramas)
                                                    ).toFixed(2)
                                                }}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div v-if="c.comenzi.length > 0">
                                    <div class="sub-title">Comenzi cu cardul</div>
                                    <el-table comp-table border :data="c.comenzi" style="width: 100%">
                                        <el-table-column prop="oid" label="ID Comanda"> </el-table-column>
                                        <el-table-column prop="data" label="Data"> </el-table-column>
                                        <el-table-column prop="valoare" label="Status comanda">
                                            <template slot-scope="scope">
                                                {{ getOrderStatus(scope.row.status) }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="valoare" label="Mod de plata">
                                            <template>
                                                Online
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="valoare" label="Valoare comanda"> </el-table-column>
                                        <el-table-column prop="valoare" label="Suma compensata">
                                            <template slot-scope="scope">
                                                {{
                                                    parseFloat(
                                                        parseFloat(scope.row.valoare) - parseFloat(scope.row.ramas)
                                                    ).toFixed(2)
                                                }}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <div class="separator"></div>
                            <div class="invoices">
                                <div class="title">
                                    <span class="color-cel">{{ userName }}</span> datoreaza
                                    <span class="color-cel">{{ compensationCompany(c) }}</span> suma de
                                    <span class="color-cel">{{ parseFloat(c.sumaCompensata).toFixed(2) }}</span> lei
                                    conform detaliilor de mai jos:
                                </div>
                                <div v-if="typeof c.restanteAfiliat !== 'undefined' && c.restanteAfiliat.length > 0">
                                    <div class="sub-title">
                                        Plati restante <span class="color-cel">{{ userName }}</span>
                                    </div>
                                    <el-table comp-table border :data="c.restanteAfiliat" style="width: 100%">
                                        <el-table-column prop="id" label="ID"> </el-table-column>
                                        <el-table-column prop="data" label="Data"> </el-table-column>
                                        <el-table-column prop="detalii" label="Observatii"> </el-table-column>
                                        <el-table-column prop="valoare" label="Valoare"> </el-table-column>
                                        <el-table-column prop="ramas" label="Suma compensata">
                                            <template slot-scope="scope">
                                                {{
                                                    parseFloat(
                                                        parseFloat(scope.row.valoare) - parseFloat(scope.row.ramas)
                                                    ).toFixed(2)
                                                }}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="sub-title">Facturi comisioane</div>
                                <el-table class="comp-table" border :data="c.facturi" style="width: 100%">
                                    <el-table-column prop="nr" label="Nr Factura"> </el-table-column>
                                    <el-table-column prop="data" label="Data"> </el-table-column>
                                    <el-table-column prop="ramas" label="Nume client">
                                        <template slot-scope="scope">
                                            {{
                                                scope.row.denumire_companie === ''
                                                    ? userName
                                                    : scope.row.denumire_companie
                                            }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="valoare" label="Valoare factura"> </el-table-column>
                                    <el-table-column prop="platit_partial" label="Plati anterioare"> </el-table-column>
                                    <el-table-column prop="platit_partial" label="Ramas de compensat">
                                        <template slot-scope="scope">
                                            {{
                                                parseFloat(
                                                    parseFloat(scope.row.valoare) - parseFloat(scope.row.platit_partial)
                                                ).toFixed(2)
                                            }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="valoare" label="Suma compensata">
                                        <template slot-scope="scope">
                                            {{
                                                parseFloat(
                                                    parseFloat(scope.row.valoare) -
                                                        parseFloat(scope.row.platit_partial) -
                                                        parseFloat(scope.row.ramas)
                                                ).toFixed(2)
                                            }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ramas" label="Suma ramasa">
                                        <template slot-scope="scope">
                                            {{ parseFloat(scope.row.ramas).toFixed(2) }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-col>
                    </el-row>
                </dd-card>
            </template>
            <template v-else>
                <div class="center">Nu exista compensari.</div>
            </template>
        </div>
    </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import createCompensationsStore from '@/store/modules/compensations/index.js';

const DDCard = () => import('@/components/DDCard');
import capitalize from 'lodash.capitalize';
const Box = () => import('vue-info-box-element');

export default {
    components: {
        'dd-card': DDCard,
        Box
    },
    data() {
        return {
            isCollapsed: false,
            filters: {
                selectedInterval: {
                    type: 'select',
                    value: ''
                },
                status: {
                    type: 'select',
                    value: ''
                },
                compensationID: {
                    type: 'input',
                    value: ''
                }
            },
            ordersStatus: {
                26: 'Disputata',
                25: 'Finalizata',
                24: 'Stornata',
                11: 'Anulata',
                1: 'Nealocata',
                2: 'Alocata'
            },
            statuses: [
                {
                    text: this.$t('payments.compensations.statuses.all'),
                    value: ''
                },
                {
                    text: this.$t('payments.compensations.statuses.active'),
                    value: 0
                },
                {
                    text: this.$t('payments.compensations.statuses.completed'),
                    value: 1
                },
                {
                    text: this.$t('payments.compensations.statuses.canceled'),
                    value: -1
                }
            ]
        };
    },
    computed: {
        userName: get('user/userName'),
        compensations: get('compensations@compensations/list'),
        intervals: get('compensations@intervals'),
        intervalsStrings() {
            if (this.intervals.length < 1) return [];
            return this.intervals.map((item) => {
                const label =
                    this.$moment(item['_id'].start, 'YYYY-MM-DD').format('LL') +
                    ' - ' +
                    this.$moment(item['_id'].end, 'YYYY-MM-DD').format('LL');
                return {
                    value: `${item['_id'].start}->${item['_id'].end}`,
                    label
                };
            });
        }
    },
    methods: {
        getCompensations: call('compensations/GetCompensations'),
        capitalize,
        getIntervals: call('compensations/GetIntervals'),
        getOrderStatus(status) {
            return this.ordersStatus[parseInt(status)] || 'Finalizata';
        },
        getSummariesNotIncluded(param) {
            let total = 0;
            param.data.forEach((d) => {
                total += parseFloat(d.ramas);
            });
            return ['Total', parseFloat(total).toFixed(2)];
        },
        getSummaries(param) {
            let total = 0;
            param.data.forEach((d) => {
                const platitPartial = d.platit_partial || 0;
                total += parseFloat(d.valoare) - parseFloat(platitPartial) - parseFloat(d.ramas);
            });
            return ['Total', '', '', '', parseFloat(total).toFixed(2)];
        },
        compMessage(c) {
            const diference = c.ramasDeIncasat > 0 ? c.ramasDeIncasat : c.ramasDePlatit;
            if (diference == 0) {
                return (
                    'In urma compensarii, nu exista diferente de achitat din partea <span class="color-cel">' +
                    this.compensationCompany(c) +
                    '</span> sau <span class="color-cel">' +
                    this.userName +
                    '</span>'
                );
            }
            let message =
                'In urma compensarii, diferenta de <span class="color-cel">' +
                parseFloat(diference).toFixed(2) +
                '</span> lei va fi achitata de ';
            if (c.ramasDeIncasat > 0) {
                message +=
                    '<span class="color-cel">' +
                    this.userName +
                    '</span> societatii <span class="color-cel">' +
                    this.compensationCompany(c) +
                    '</span>';
                if (c.paymentCode != null) {
                    message +=
                        '<div class="payment-code-container">' +
                        this.$t('payments.compensations.payment-text') +
                        ' <span class="payment-code cel-color"><strong>' +
                        c.paymentCode +
                        '</strong></span></div>';
                }
            } else {
                message +=
                    '<span class="color-cel">' +
                    this.compensationCompany(c) +
                    '</span> partenerului Marketplace <span class="color-cel">' +
                    this.userName +
                    '</span>';
            }
            return message;
        },
        async downloadCompensation(id) {
            try {
                const res = await this.$store.dispatch('compensations/DownloadCompensation', {
                    id
                });
                if (typeof res.message.data !== 'undefined' && res.message.data !== '') {
                    const linkSource = `data:application/pdf;base64,${res.message.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = res.message.title + '.pdf';

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();

                    return false;
                }
            } catch (err) {}
        },
        applyFilters() {
            this.getCompensations(this.filters).catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getCompensations'
                    }
                });
            });
        },
        resetFilters() {
            for (const filter in this.filters) {
                this.filters[filter].value = '';
            }
            this.getCompensations(this.filters).catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getCompensations'
                    }
                });
            });
        },
        formatInterval(interval) {
            return (
                this.$moment(interval.start, 'YYYY-MM-DD').format('LL') +
                ' - ' +
                this.$moment(interval.end, 'YYYY-MM-DD').format('LL')
            );
        },
        compensationCompany(c) {
            return c.companyName != null ? c.companyName : 'Corsar Online SRL';
        }
    },
    watch: {
        filters(val) {
            console.log(val);
        }
    },
    beforeCreate() {
        this.$store.registerModule('compensations', createCompensationsStore());
    },
    async mounted() {
        await this.getIntervals();
        this.getCompensations(this.filters);
    },
    beforeDestroy() {
        this.$store.unregisterModule('compensations');
    }
};
</script>
<style lang="scss" scoped>
::v-deep .payment-code-container {
    padding: 10px;
    margin: 10px 0;
    font-weight: 700;
    border: 1px solid #051e34;
    color: #5e6d82;
}

.box-card {
    font-size: 14px !important;
}

.sub-title {
    font-weight: 500;
    color: #6f7e94;
}

.item {
    line-height: 1.5em;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6ebf5;
}

.left-title {
    font-size: 15px;
    color: #5e6d82;
    font-weight: bold;
}

.title {
    margin-bottom: 10px !important;
    font-size: 14px !important;
}

.tables-container {
    border-left: 1px solid #e6ebf5;
    padding-left: 20px !important;

    .title {
        font-weight: normal !important;
    }
}

.details-container {
    padding-right: 20px !important;
}

.left-values {
    font-size: 13px;
}

::v-deep .color-cel {
    color: rgb(255, 103, 35);
    font-weight: bold;
}

.compensation-card:not(:last-child) {
    margin-bottom: 20px;
}

.comp-message {
    padding: 15px;
    border: 1px solid #e6ebf5;
    text-align: center;
    color: #5e6d82;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
}

@media (max-width: 1000px) {
    .details-container {
        padding-right: 5px !important;
    }

    .tables-container {
        border-left: none;
        padding-left: 5px !important;
    }
}
</style>
