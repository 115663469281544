import store from '@/store/index';
import { getCompensations, downloadCompensation, getCompensationsIntervals } from '@/api/plati';
export default {
    GetCompensations: ({ state }, filters) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCompensations(filters);
                if (res.message == null) return reject(new Error());
                store.set('compensations/compensations@list', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    DownloadCompensation: ({ commit }, { id }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await downloadCompensation({
                    id
                });
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetIntervals: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCompensationsIntervals();
                if (res.message == null) return reject(new Error());
                store.set('compensations/intervals', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    }
};
