import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import { make } from 'vuex-pathify';

export const initialState = () => ({
    intervals: [],
    compensations: {
        list: [],
        start: 0
    }
});

const mutations = {
    ...make.mutations(initialState()),
    ...mutationsFile
};

const getters = {
    ...make.getters(initialState()),
    ...gettersFile
};

export default function createCompensationsStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations
    };
}
